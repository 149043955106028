import { get } from "@nhi/utils"

/**
 * Fetch page fragment by path
 * @returns fragment from given path
 */
export async function getFragment(path: string) {
    const endpoint =
        process.env.NEXT_PUBLIC_CONTENT_API +
        `/Config/Fragment?path=${encodeURIComponent(path)}`

    const { data } = await get(endpoint)
    return data
}
