import React from "react"
import { A11y, Autoplay, Navigation, Pagination, Parallax } from "swiper"
import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/navigation"
import "swiper/css/pagination"
import { Swiper, SwiperSlide } from "swiper/react"
import { t } from "translations"

import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { IContainerCard } from "data/contentData/interfaces/mongodb/IContainerCard"

import Icon from "Components/Icon/Icon"
import ContentImage from "Components/Image/ContentImage"
import Link from "Components/Link"

export default function CarouselContainer({ card }: { card: IContainerCard }) {
    const listTemplatePageUrl = card.cards[0].url.split("/")[1]

    return (
        <StyledSwiperWrapper>
            <StyledSwiper
                loop={true}
                speed={600}
                parallax={true}
                autoplay={{
                    disableOnInteraction: true,
                    pauseOnMouseEnter: true,
                    stopOnLastSlide: false,
                    delay: 5000
                }}
                navigation={true}
                a11y={{
                    prevSlideMessage: t.gallery.previousSlide,
                    nextSlideMessage: t.gallery.nextSlide
                }}
                modules={[Parallax, Pagination, Navigation, Autoplay, A11y]}>
                <StyledParllax
                    slot="container-start"
                    data-swiper-parallax="-23%"
                />
                {card.cards.map(cardsCard => (
                    <StyledSwiperSlide key={cardsCard.image.src}>
                        <StyledLink
                            href={cardsCard.url}
                            color="inherit">
                            <Grid
                                direction={"row"}
                                gap={4}
                                p={2}
                                pb={0}
                                container
                                item
                                justifyContent={"center"}
                                alignItems={"center"}
                                xs={12}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={"auto"}
                                    data-swiper-parallax="-300">
                                    <StyledContentImage
                                        image={cardsCard.image}
                                    />
                                </Grid>
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    sm={6}
                                    gap={2}>
                                    <Grid
                                        item
                                        container
                                        justifyContent={"center"}>
                                        <StyledCardTopicTitle
                                            color="inherit"
                                            variant="overline"
                                            data-swiper-parallax="-100">
                                            {card.title}
                                        </StyledCardTopicTitle>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        gap={1}>
                                        <StyledTitle
                                            color="inherit"
                                            variant="h2"
                                            data-swiper-parallax="-300">
                                            {cardsCard.title}
                                        </StyledTitle>
                                        <Typography
                                            variant="body1"
                                            data-swiper-parallax="-200"
                                            color="inherit">
                                            {cardsCard.ingress}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </StyledLink>
                    </StyledSwiperSlide>
                ))}
            </StyledSwiper>
            <Grid
                item
                container
                justifyContent={"center"}
                alignItems={"center"}>
                <StyledButton
                    href={`/${listTemplatePageUrl}`}
                    variant="contained"
                    color="secondary"
                    endIcon={<Icon name="arrowForwardCircleOutlined" />}
                    data-swiper-parallax="-400">
                    {t.gallery.seeMoreArticles}
                </StyledButton>
            </Grid>
        </StyledSwiperWrapper>
    )
}

const StyledSwiperWrapper = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    border: `${theme.spacing(0.25)} solid ${theme.palette.secondary.dark}`,
    width: "100%",
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up("md")]: {
        padding: theme.spacing(4)
    }
}))

const StyledSwiper = styled(Swiper)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    "--swiper-navigation-color": "white"
}))

const StyledSwiperSlide = styled(SwiperSlide)(({ theme }) => ({
    textAlign: "center",
    color: "white",
    WebkitBoxSizing: "border-box",
    boxSizing: "border-box",
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
        textAlign: "left",
        padding: `${theme.spacing(2)} ${theme.spacing(4)}`
    }
}))

const StyledLink = styled(Link)({
    "&:hover": {
        textDecoration: "none"
    }
})

const StyledContentImage = styled(ContentImage)({
    borderRadius: "50%",
    height: 250,
    width: 250,
    objectFit: "cover"
})

const StyledCardTopicTitle = styled(Typography)(({ theme }) => ({
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
        marginLeft: 0,
        marginRight: "auto"
    }
}))

const StyledTitle = styled(Typography)(({ theme }) => ({
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("md")]: {
        textAlign: "left",
        marginLeft: 0,
        marginRight: "auto"
    },
    "&:hover": {
        textDecoration: "underline"
    }
}))

const StyledButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    "&:hover": {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.contrastText
    },
    [theme.breakpoints.up("md")]: {
        marginLeft: "auto",
        marginRight: 0,
        float: "right"
    }
}))

const StyledParllax = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    position: "absolute",
    left: 0,
    top: 0,
    width: "130%",
    height: "100%",
    WebkitBackgroundSize: "cover",
    backgroundSize: "cover",
    backgroundPosition: "center"
}))
