import { IArticleCard } from "data/contentData/interfaces/mongodb/IArticleCard"
import { IArticlesCard } from "data/contentData/interfaces/mongodb/IArticlesCard"
import { IBlock } from "data/contentData/interfaces/mongodb/IBlock"
import { IContainerCard } from "data/contentData/interfaces/mongodb/IContainerCard"

import PregnancyOracleFrontpageCard from "Components/PregnancyOracle/PregnancyOracleFrontpageCard"

import ArticlesCard from "../TopicArticlesCard"
import BlurbContainer from "./BlurbContainer"
import CarouselContainer from "./CarouselContainer"
import LinkBoxContainer from "./LinkBoxContainer"
import SplashContainer from "./SplashContainer"

export default function TopicContainerCard({ block }: { block: IBlock }) {
    const containerCard = block as IContainerCard

    switch (containerCard.type) {
        case "pregnancy":
            return <PregnancyOracleFrontpageCard />
        case "blurbs":
            return <BlurbContainer card={containerCard} />
        case "splash":
            return <SplashContainer card={containerCard} />
        case "carousel":
            return <CarouselContainer card={containerCard} />
        case "link-box":
            return <LinkBoxContainer card={containerCard} />
        default:
            const articlesCard: IArticlesCard = {
                ...containerCard,
                articles: containerCard.cards?.map(
                    c =>
                        ({
                            ...c
                        } as IArticleCard)
                ),
                source: "",
                templateGroup: "",
                skip: 0,
                take: 0,
                thumbnails: false,
                sortOrder: 0
            }

            return <ArticlesCard card={articlesCard} />
    }
}
