import React, { useEffect, useState } from "react"
import { t } from "translations"

import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { IPublic } from "data/contentData/interfaces/mongodb/IPublic"

import { IPagingContext } from "Components/Layout/PageComponent/PageDataProvider"
import Link from "Components/Link"
import PortableText from "Components/PortableText/PortableText"

import Sections from "../Sections"
import ToC from "./ToC"
import shouldAbTestPaging from "lib/pagingAbTesting"
import getQueryStringValue from "lib/getQueryStringValue"
import isNumeric from "lib/isNumeric"

export default function PagedSections({
    page,
    paging
}: {
    page: IPublic
    paging: IPagingContext | null
}) {
    const [pageIndex, setPageIndex] = useState(paging?.paging.currentPageNumber ?? 0)

    useEffect(() => {
        const params = getQueryStringValue("page")
        const tempShouldAbtest = shouldAbTestPaging(page.url)
        if (!tempShouldAbtest && params && isNumeric(params))
            setPageIndex(parseInt(params as string) - 1)
        else if (tempShouldAbtest && paging?.paging.currentPageNumber)
            setPageIndex(paging?.paging.currentPageNumber)
        else
            setPageIndex(0)
    }, [page, paging?.paging.currentPageNumber])

    if (!page) return null

    const abTestPaging = shouldAbTestPaging(page.url)

    const currentSection = paging?.pages[pageIndex]

    const isLastSection =
        abTestPaging ? paging?.paging.currentPageNumber === (paging?.pages.length ?? 0) - 1 : pageIndex + 1 === (paging?.pages.length ?? 0)
    const isFirstSection = abTestPaging ? paging?.paging.currentPageNumber === 0 : pageIndex === 0
    const getHeaderVariant = isFirstSection ? "h2" : "h1"

    function getHeaderValue(currentSectionName: string) {
        if (isFirstSection) return currentSectionName

        if (!abTestPaging) return currentSectionName

        const wordsInPage = page.name.toLowerCase().split(" ")
        if (
            wordsInPage.some(
                wordInPage => currentSectionName.toLowerCase().indexOf(wordInPage) > -1
            )
        )
            return currentSectionName

        return `${page.name}: ${currentSectionName}`
    }

    function showHeader(currentSectionName: string) {
        let result = currentSectionName ? true : false

        if (result && isFirstSection) result = page.name !== currentSectionName

        return result
    }

    return (
        <>
            {currentSection && (
                <section>
                    {currentSection.leadText && (
                        <PortableText value={currentSection.leadText} />
                    )}
                    {showHeader(currentSection.name) && (
                        <StyledHeader
                            isfirstsection={isFirstSection}
                            variant={getHeaderVariant}>
                            {getHeaderValue(currentSection.name)}
                        </StyledHeader>
                    )}
                    {!isFirstSection && (
                        <ToC
                            page={page}
                            paging={paging}
                        />
                    )}
                    <PortableText value={currentSection.blockContent} />
                    {currentSection.sections &&
                        currentSection.sections.length > 0 && (
                            <Sections
                                sections={currentSection.sections}
                                level={2}
                            />
                        )}
                </section>
            )}

            <StyledButtonWrapper>
                <StyledLink
                    href={getPreviousUrl(abTestPaging, page.url, paging, pageIndex)}
                    disabled={isFirstSection}>
                    <Button
                        variant="contained"
                        disabled={isFirstSection}>
                        {t.article.previousButton}
                    </Button>
                </StyledLink>
                <StyledLink
                    href={getNextUrl(abTestPaging, page.url, paging, pageIndex)}
                    disabled={isLastSection}>
                    <Button
                        variant="contained"
                        disabled={isLastSection}>
                        {t.article.nextButton}
                    </Button>
                </StyledLink>
            </StyledButtonWrapper>
        </>
    )
}

/**
 * get previous url path
 * @param pageUrl Get previous url path
 * @param previousPage article url
 * @returns return previous url path without page number if page number is 1
 */
function getPreviousUrl(abTestPaging: boolean, pageUrl: string, paging: IPagingContext | null, pageIndex: number) {
    if (abTestPaging) {
        if (!paging?.paging.prev) return pageUrl

        if (pageUrl.endsWith("/")) return `${pageUrl}${paging?.paging.prev}`

        return `${pageUrl}/${paging?.paging.prev}`
    }

    if (pageIndex === 1) return pageUrl
    return `${pageUrl}?page=${pageIndex}`
}

function getNextUrl(abTestPaging: boolean, pageUrl: string, paging: IPagingContext | null, pageIndex: number) {
    if (!paging?.paging.next) return pageUrl

    if (abTestPaging) {
        if (pageUrl.endsWith("/")) return `${pageUrl}${paging?.paging.next}`
        return `${pageUrl}/${paging?.paging.next}`
    }

    return `${pageUrl}?page=${pageIndex + 2}`
}

const StyledButtonWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(4)
}))

const StyledLink = styled(Link)(() => ({
    textDecoration: "none",
    "&:hover": {
        textDecoration: "none"
    }
}))

const StyledHeader = styled(Typography)<{ isfirstsection: boolean }>(
    ({ theme, isfirstsection }) => ({
        paddingBottom: isfirstsection ? theme.spacing(0) : theme.spacing(2)
    })
)
